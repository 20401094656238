<template>
    <b-card title="Mail Templates">
        <div style="width: 100%;">
            <p style="margin: 40px 0px; color: #b13032;">** Backend is not connected yet</p>
            <div class="main-div-layout">
                <div class="main-template-layout">
                    <div v-if="test">
                        <div style="width: 100%;">
                            <div style="display: flex; gap: 4px; margin-bottom: 12px;">
                                <div class="big-screen-width">
                                    <div>
                                        <b-form-select v-model="template"> 
                                            <b-form-select-option v-for="option in options" :value="option.value">{{ option.title }}</b-form-select-option>
                                        </b-form-select>
                                    </div>
                                </div>
                            </div>

                            <div v-if="template == 'link_for_email_verification'">
                                <div style="width: 100%;">
                                    <vue-editor rows="5" v-model="link_for_email_verification"></vue-editor> 
                                </div>
                            </div>
                            <div v-else-if="template == 'further_review'">
                                <div>
                                    <vue-editor rows="5" style="width: 100%;" v-model="further_review"></vue-editor> 
                                </div>
                            </div>
                            <div v-else-if="template == 'activation'">
                                <div>
                                    <vue-editor rows="5" v-model="activation"></vue-editor> 
                                </div>
                            </div>
                            <div v-else-if="template == 'pending_match_payment'">
                                <div>
                                    <vue-editor rows="5" v-model="pending_match_payment"></vue-editor> 
                                </div>
                            </div>
                            <div v-else-if="template == 'payment_with_receipt'">
                                <div>
                                    <vue-editor rows="5" v-model="payment_with_receipt"></vue-editor> 
                                </div>
                            </div>
                            <div v-else-if="template == 'viewed_by_five_profiles'">
                                <div>
                                    <vue-editor rows="5" v-model="viewed_by_five_profiles"></vue-editor> 
                                </div>
                            </div>
                            <div v-else-if="template == 'name_number_match'">
                                <div>
                                    <vue-editor rows="5" v-model="name_number_match"></vue-editor> 
                                </div>
                            </div>
                            <div v-else>
                                <div>
                                    <vue-editor rows="5"></vue-editor>  
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style="display: flex; gap: 16px; margin-top: 16px;">
                        <b-button style="height: 40px;" @click="submitTemplate" variant="primary">Save Template</b-button>
                    </div>
                </div>

                <div class="main-demo-layout">
                    <div style="display: flex; width: 100%; justify-content: center;">
                        <div style="width: 100%;">
                            <html>
                                <head></head>
                                <div id="emailtemplate">
                                    <body>
                                        <table align="center">
                                            <tbody>
                                                <tr>
                                                    <td style="width: 20px;"> </td>
                                                    <td style="width: 600px; background-color: #ffffff;">
                                                        <div> 
                                                            <!-- HEADER -->
                                                            <div style="background-color: #FFFBFE; display: flex; align-items: center; justify-content: center; padding: 12px 0px;">
                                                                <table width="600">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td style="width: 2%;"> </td>
                                                                            <td style="width: 96%;" align="center">
                                                                                <img src="https://api-biyeruti.smicee.com/media/logo.png" alt=""> 
                                                                            </td>
                                                                            <td style="width: 2%;"> </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div> 

                                                            <!-- BODY -->
                                                            <div style="width: 600px;">
                                                                <div style="color: #1E1E1E; margin-top: 50px; margin-bottom: 50px; margin-left: 20px; margin-right: 20px;">
                                                                    <p style="font-size: larger;">Dear {user.first_name},</p>
                                                                    <p style="font-size: medium;">Welcome to our platform! Please verify your email address to complete your registration.</p>
                                                                    <p style="font-size: medium;">Click <a href='{verification_link}'>here</a> to verify your email address.</p>
                                                                    <p style="font-size: medium;">If you did not create an account, please ignore this email.</p>
                                                                    <p style="margin-top: 35px; font-size: medium;">Best regards,<br><span style="font-weight: 500; margin-top: 15px;">Team BiyerJuti</span></p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <!-- FOOTER -->
                                                        <div style="width: 600px;">
                                                            <div style="width: 600px; height: 100%; background-color: #b13032; color: #ffffff;">
                                                                <div style="padding: 12px 0px;">
                                                                    <table width="600" style="background-color: #b13032; color: #ffffff;">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td style="max-width: 2%;"> </td>
                                                                                <td style="font-weight: 500;" align="center">
                                                                                    <span style="font-size: 14px;">�� 2025 BiyerJuti. All rights reserved.</span>
                                                                                </td>
                                                                                <td style="max-width: 2%;"> </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td style="width: 20px;"> </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </body>
                                </div>
                            </html>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </b-card>
</template>
  
<script>
import { BAvatar,BButton, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BFormSelectOption, BDropdown, BDropdownItem, BCard,
BModal,VBModal,BCardText, BFormTextarea, BCalendar, BFormDatepicker } from 'bootstrap-vue';
import store from '@/store/index';
import Ripple from "vue-ripple-directive";
import { mapState } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
// import fileImage from '../../../../assets/images/templates/file-thumbnail.png';
import { VueEditor } from "vue2-editor"; 
import { template } from 'leaflet/src/core/Util';

export default {
    components: {
        BAvatar,
        BCard,
        BBadge,
        BButton,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BFormSelectOption,
        BDropdown,
        BDropdownItem,
        BModal,
        BCardText,
        BFormTextarea,
        BCalendar,
        BFormDatepicker,
        VueEditor
    },

    directives: {
        'b-modal': VBModal,
        Ripple,
    },

    data() {
        return {
            test: true,
            host: 'https://api-swissnews.smicee.com',
            header: null,
            template: null,
            link_for_email_verification: null,
            further_review: null,
            activation: null,
            pending_match_payment: null,
            payment_with_receipt: null,
            viewed_by_five_profiles: null,
            name_number_match: null,
            options: [
                {
                    title: 'Select a template',
                    value: 'null',
                    disabled: true
                },
                {
                    title: 'Link for Email Verification', 
                    value: 'link_for_email_verification'
                },
                {
                    title: 'Further Review',
                    value: 'further_review'
                },
                {
                    title: 'Activation',
                    value: 'activation'
                },
                {
                    title: 'Pending Match Payment',
                    value: 'pending_match_payment'
                },
                {
                    title: 'Payment with Receipt',
                    value: 'payment_with_receipt'
                },
                {
                    title: 'Viewed by 5 Profiles',
                    value: 'viewed_by_five_profiles'
                },
                {
                    title: 'Name and Number of Match',
                    value: 'name_number_match'
                },
            ]
        }
    },

    computed: {
        emailBodyText() {
            switch (this.template) {
                case 'link_for_email_verification':
                return this.link_for_email_verification;
                case 'further_review':
                return this.further_review;
                case 'activation':
                return this.activation;
                case 'pending_match_payment':
                return this.pending_match_payment;
                case 'payment_with_receipt':
                return this.payment_with_receipt;
                case 'viewed_by_five_profiles':
                return this.viewed_by_five_profiles;
                case 'name_number_match':
                return this.name_number_match;
                default:
                return null;
            }
        }
    },

    methods: {
        testToggle(){
            if(this.test) {
                this.test = false
            }else{
                this.test = true
            }
        },

        submitTemplate() {
            this.innerhtml = document.getElementById('emailtemplate').innerHTML;
            if(this.template) {
                console.log('inner_html:', this.innerhtml);
                console.log('template:', this.template);
                console.log('body_text:', this.emailBodyText);

                // this.$store.dispatch('mail/saveTemplate', {inner_html: this.innerhtml, email_template: this.template, body_text: this.emailBodyText}).then(result=>{
                //     if(result.code == 200) {
                //         this.$toast({
                //             component: ToastificationContent,
                //             position: 'top-right',
                //             props: {
                //                 title: `Success`,
                //                 icon: 'ThumbsUpIcon',
                //                 variant: 'success',
                //                 text: result.response,
                //             }
                //         })
                //     }
                // })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';

/* height */
::-webkit-scrollbar {
    height: 6px;
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.main-div-layout {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.main-template-layout {
    width: 70%;
}

.main-demo-layout {
    width: 100%;
}

@media screen and (max-width: 991px) {
    .main-template-layout {
        width: 100%;
    }
}
</style>